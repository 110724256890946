<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="搜索快讯内容"
            class="search-input"
            v-model="keyword"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="showNewsDialog('add')"
                >新增快讯</el-button
              >
              <el-popover
                placement="top-start"
                title="说明"
                width="280"
                trigger="hover"
                :content="intro">
                <el-button slot="reference" class="ml-15" plain icon="el-icon-question">说明</el-button>
              </el-popover>
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="快讯内容" prop="title"></el-table-column>
            <el-table-column
              label="创建时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="operations">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span class="tip-text-info" @click="showNewsDialog('edit', scope.row.id)">
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <!-- <el-link type="danger" :underline="false" class="ml-10"> -->
                  <span class="ml-10 tip-text-delete" @click="deleteNews(scope.row.id)">
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="total" :pageNum="pageNum" :pageSize="pageSize" :pageSizes="pageSizes" @changePage="search" @syncPageData="syncPageData"></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showNews" width="60%" :title="opeType=='add'?'添加快讯':'编辑快讯'" @close="clearDialog">
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="news_form"
          :rules="rules"
          ref="news_form"
        >
          <el-form-item label="详情内容" prop="news">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="news_form.news"
              maxlength="16"
              rows="3"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showNews = false"
            >取消</el-button
          >
          <el-button class="form-button-w70" size="small" type="primary" @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index"
import request from "@/utils/request"
export default {
  name: "miniprogram_news",
  components: {
    Pagination,
  },
  data() {
    return {
      keyword: "",
      // 优惠券表格数据内容
      tableData: [],
      intro: `1.轮播顺序：按最新上架顺序优先展示
              2.不设置上限数，上下循环播放`,
      showNews: false,
      opeType: 'add',
      news_form: {
        news: ""
      },
      rules: {
        news: [{ required: true, message: "内容不能为空", trigger: 'blur'}]
      },
      newsId: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.search()
  },
  methods: {
    // 搜索事件
    search() {
      let params = {
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      request({
        url: "/small-inteface/user-service/sysNotice/list",
        method: "get",
        params
      }).then(res => {
        if(res.data.code == 200) {
          this.tableData = res.data.body.list
          this.total = res.data.body.total
        }
      })
    },
    getNews(id) {
      request({
        url: "/small-inteface/user-service/sysNotice/" + id,
        method: "get",
      }).then(res => {
        if(res.data.code == 200) {
          this.news_form.news = res.data.body.title
        }
      })
    },
    //同步子组件修改的数据
    syncPageData(data) {
      this.pageNum = data.num
      this.pageSize = data.size
    },
    // 重置搜索关键词
    reset() {
      this.keyword = ""
      this.search()
    },
    clearDialog() {
      this.news_form.news = ""
    },
    upDownShelf(e, id){
      request({
        url: `/small-inteface/user-service/sysNotice/${id}/${e}`,
        method: "put",
      }).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
        }
      })
    },
    deleteNews(id) {
      this.$confirm("此操作将永久删除该快讯, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
        // type: 'warning'
      })
        .then(() => {
          request({
            url: "/small-inteface/user-service/sysNotice/" + id,
            method: "delete",
          }).then(res => {
            if(res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              })
              this.search()
            }
          })
          ;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    showNewsDialog(type, id) {
      this.showNews = true;
      this.opeType = type
      if(id != undefined) {
        this.newsId = id
        this.getNews(id)
      }
    },
    submit() {
      this.$refs['news_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.submitForm()
      })
    },
    submitForm() {
      let method = this.opeType == "add" ? "post" : "put"
      let data = {
        title: this.news_form.news
      }
      if(this.opeType == 'edit') {
        data.id = this.newsId
      }
      request({
        url: "/small-inteface/user-service/sysNotice",
        method,
        data
      }).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
          this.showNews = false
          this.title = ""
          this.search()
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
